<template>
  <CContainer class="min-vh-100 d-flex align-items-center">
    <CRow class="w-100 justify-content-center">
      <CCol md="6" sm="8">
        <CCard class="mx-4 mb-0">
          <CCardBody class="p-4">
              <h1>Forgot Password</h1>
              <p class="text-muted">Fill in the form below to receive a password reset mail</p>
              <CInput
                placeholder="Email"
                autocomplete="email"
                prepend="@"
                v-model="email"
              />
              <CButton v-on:click="requestPasswordResetLink" color="success" block>Request Reset Password Link</CButton>
              <p class='message errorMessage' v-html="errorMessage"></p>
              <p class='message successMessage' v-html="successMessage"></p>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </CContainer>
</template>

<script>

import AuthAPI from '/app/src/api/auth.js'

export default {
    name: 'ForgotPassword',
    data () {
    return {
        email: '',
        successMessage: '',
        errorMessage: '',
        authAPI: new AuthAPI()
    }
  },
  methods: {
    requestPasswordResetLink: function () {

      this.$store.dispatch('loading')
      let self = this
      
      this.authAPI.requestPasswordResetLink(self.email)
      .then((result) => {
        this.successMessage = "If an account exists with your e-mail a link to reset your password has been sent to it. Don't forget to check your spam folder."
        this.errorMessage = ''
        this.$store.dispatch('stopLoading')
      })
      .catch((error) => {
        this.successMessage = ''
        this.errorMessage = error
        this.$store.dispatch('stopLoading')
      })
      
    }
  }
}
</script>

<style scoped>

    .message {
        margin-top: 20px;
    }

    .message {
        margin-top: 20px;
    }

    .successMessage {
        color: green;
    }

    .errorMessage {
        color: red;
    }

</style>